.body {
    background              : #fff;
    position                : relative;
    font-size               : 16px;
    overflow                : hidden;
    margin                  : 0 auto;
    color                   : #60697B;
    line-height             : 1.7;
    -webkit-text-size-adjust: 100%;
    font-family             : 'Open Sans', sans-serif;
    -webkit-font-smoothing  : antialiased;

    article,
    aside,
    br,
    dialog,
    figure,
    footer,
    header,
    hr,
    main,
    menu,
    nav,
    section {
        display: block;
    }

    .transition {
        transition: all 0.2s ease-in-out
    }

    img,
    svg {
        vertical-align: middle;
    }

    .slider {
        position   : relative;
        display    : block;
        box-sizing : border-box;
        user-select: none;
        overflow   : hidden;

        .siler-list {
            transform: translate3d(0, 0, 0);

            .slider-item {
                background-size    : cover;
                background-repeat  : no-repeat;
                background-position: 50%;
                display            : flex;
                align-items        : center;
                background-image   : url('https://ntq.com.vn/media/WHg55YEnRYeIlBgyPU9k/');
                height             : 100vh !important;
                min-height         : 1px;
                float              : left;
                width              : 100% !important;
                background-color   : #f1f1f1;

                .container {
                    position: relative;

                    .text {
                        overflow      : hidden;
                        // transform  : translateY(80px);

                        .text-title {
                            color            : #ffffff;
                            font-size        : 45px;
                            line-height      : 38px;
                            font-weight      : 800;
                            text-transform   : uppercase;
                            // margin        : 0px;
                        }

                        .sub-text {
                            color      : #ffffff;
                            font-size  : 20px;
                            line-height: 45px;
                        }
                    }
                }
            }
        }
    }

    main {
        overflow        : hidden;
        position        : relative;
        background-color: white;

        .section-services {
            margin-top   : 40px;
            margin-bottom: 40px;

            .container {
                position: relative;

                .title {
                    margin-top : 20px;
                    font-weight: bold;
                    font-size  : 29px;
                    color      : #0060ad;

                }

                .desc {
                    font-size: 18px;
                    color    : #60697B;
                    float    : left;
                }

                .c4-item {
                    margin-top: 50px;

                    .item {
                        padding-left : 15px;
                        padding-right: 15px;
                        text-align   : center;

                        .title {
                            font-size    : 20px;
                            color        : #60697B;
                            text-align   : center;
                            margin-bottom: 20px;
                        }

                        .desc {
                            font-size : 15px;
                            color     : #60697B;
                            text-align: center;

                        }
                    }
                }
            }
        }


        .contact-us::before {
            content   : '';
            position  : absolute;
            top       : 0;
            left      : 0;
            width     : 100%;
            height    : 100%;
            opacity   : 0.8;
            background: linear-gradient(to bottom right, #0060AD 0%, #8ABA2B 100%);
        }

        .contact-us {
            margin-top            : 40px;
            margin-bottom         : 40px;
            background-image      : url(https://web.archive.org/web/20220105053354im_/https://deha-soft.com/wp-content/themes/deha-viet-nam/upload/bg_hr.jpg);
            background-repeat     : no-repeat;
            // background-color   : linear-gradient(to bottom right, #0060AD 0%, #8ABA2B 100%);
            background-position   : left center;
            background-size       : cover;
            position              : relative;
            margin-bottom         : 0;

            .container {
                position: relative;

                .hrContent {
                    padding: 100px 0px;

                    h2 {
                        font-size    : 27px;
                        font-weight  : 800;
                        color        : #ffffff;
                        margin-bottom: 30px;
                    }

                    p {
                        color        : #ffffff;
                        font-size    : 18px;
                        line-height  : 28px;
                        margin-bottom: 30px;
                    }

                    a {
                        text-decoration: none;
                        color          : blue;
                        background     : white;
                        padding        : 15px 40px;
                        font-weight    : 700;

                        &:hover {
                            border: 4px #0060ad solid;
                            color : #0060ad !important
                        }
                    }
                }
            }
        }

        .our-approach {
            margin-top   : 50px;
            margin-bottom: 50px;

            .container {
                position: relative;

                .number {
                    width          : 71px;
                    height         : 71px;
                    border-radius  : 50%;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    color          : #3F78E0;
                    font-weight    : 700;
                    font-size      : 20px;
                    margin-bottom  : 20px;
                    background     : #E0E9FA;
                    margin-top     : 20px;
                }

                .title {
                    font-weight: bold;
                    font-size  : 20px;
                    color      : #60697B;

                }

                .desc {
                    font-size: 18px;
                    color    : #60697B;
                }

                .title-1 {
                    font-weight: bold;
                    font-size  : 29px;
                    color      : #0060ad;

                }

                .list {
                    font-size: 16px;
                    color    : #60697B;
                    float    : left;

                    ul {
                        list-style-type: circle;
                        margin-left    : -20px;
                    }
                }
            }
        }

        .imgv1::before {
            content   : '';
            position  : absolute;
            top       : 0;
            left      : 0;
            width     : 100%;
            height    : 100%;
            opacity   : 0.5;
            background: linear-gradient(to bottom right, #0060AD 0%, #8ABA2B 100%);
        }

        .imgv1 {
            background-position-y   : 168.379px;
            font-size               : 26px;
            text-align              : center;
            width                   : 100%;
            height                  : 350px;
            background-attachment   : fixed;
            background-position     : center 0;
            background-size         : cover;
            background-repeat       : no-repeat;
            position                : relative;
            // overflow             : hidden;
            background-image        : url('https://web.archive.org/web/20220105053405im_/https://deha-soft.com/wp-content/themes/deha-viet-nam/upload/halong-bay.jpg');

            .container {
                position : relative;
                max-width: 1000px;

                h2 {
                    align-items: center;
                    font-size  : 23px;
                    color      : white;
                    font-style : italic;

                }
            }
        }

        .secction-title-1 {
            text-align: center;
            margin-top: 20px;

            .title {
                font-size: 25px;
            }

            .sub-title {
                text-transform: uppercase;
                font-size     : 25px;
                color         : blue;
                font-weight   : 600;
            }
        }

        .section-feature-1 {
            width       : 100%;
            display     : flex;
            // flex-wrap: wrap;

            .title {
                font-size  : 30px;
                margin-left: 20px;
                font-weight: 700;
            }

            .text-content {
                margin-left: 20px;
                font-size  : 18px;
            }

            .feature-content {
                ul {
                    li {
                        background     : url(https://web.archive.org/web/20210920052015im_/https://deha-soft.com/wp-content/themes/deha-viet-nam/images/deha-arrow.png) no-repeat 0 12px !important;
                        padding-left   : 10px;
                        // font-size   : 1.8rem;
                        color          : #60697B;
                        list-style     : none;
                        font-size      : 17px;
                    }

                    p {
                        font-size: 17px;
                    }
                }
            }
        }

        .our-favourite-stacks {
            .text-title {
                .text-title-1 {
                    font-size: 27px;
                    color    : #606060;
                }

                .text-title-2 {
                    font-size              : 36px;
                    font-weight            : 800;
                    color                  : #0060ad;
                    display                : inline-block;
                    background             : linear-gradient(to right, #0060AD 0%, #8ABA2B 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .asked-questions {
            .text-title-2 {
                font-size              : 36px;
                font-weight            : 800;
                color                  : #0060ad;
                background             : linear-gradient(to right, #0060AD 0%, #8ABA2B 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-align             : center !important;
            }

            .collapse-questions {
                align-items: center;

                .faq-container {
                    display  : flex;
                    width    : 100%;
                    flex-wrap: wrap;

                    .row-item {
                        flex         : 50%;
                        padding-right: 30px;

                        .faq-question {
                            display    : flex;
                            align-items: center;

                            .question-content {
                                flex: 95%;

                                .text-color {
                                    color: blue;
                                }
                            }
                        }

                        .faq-answer {
                            .card-body {
                                // color     : red;
                                transition: all 0.2s ease-in-out;
                                transform : translate(0, 0)
                            }

                        }
                    }
                }
            }
        }

        .schedule {
            text-align: center;

            .btn-schedule {
                background-color: blue;
                color           : white;
                text-decoration : none;
                padding         : 20px 60px;
                font-size       : 20px;

                &:hover {
                    background-color: white;
                    color           : blue;
                    border          : 5px solid blue;
                }

                svg {
                    margin-bottom: 7px;
                }
            }
        }

        .our-clients {
            background-color: #d8d8d8;
            padding-top     : 40px;
            padding-bottom  : 40px;

            .container {
                position: relative;

                .title {

                    .text-v1 {
                        font-size: 32px;
                    }

                    .text-v2 {
                        font-size              : 30px;
                        font-weight            : 600;
                        color                  : #0060ad;
                        display                : inline-block;
                        background             : linear-gradient(to right, #0060AD 0%, #8ABA2B 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    .text-v3 {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media (min-width: 46.25em) and (max-width: 63.9375em) {
    .slider {
        position   : relative;
        display    : block;
        box-sizing : border-box;
        user-select: none;
        overflow   : hidden;

        .siler-list {
            transform: translate3d(0, 0, 0);

            .slider-item {
                background-size    : cover;
                background-repeat  : no-repeat;
                background-position: 50% !important;
                display            : flex;
                align-items        : center;
                background-image   : url('https://ntq.com.vn/media/WHg55YEnRYeIlBgyPU9k/');
                height             : 100vh !important;
                min-height         : 1px;
                float              : left;
                width              : 100% !important;
                background-color   : #f1f1f1;

                .container {
                    position: relative;

                    .text {
                        overflow      : hidden;
                        // transform  : translateY(80px);

                        .text-title {
                            color            : #696969;
                            font-size        : 35px !important;
                            line-height      : 38px;
                            font-weight      : 800;
                            text-transform   : uppercase;
                            // margin        : 0px;
                        }

                        .sub-text {
                            color      : #696969;
                            font-size  : 20px;
                            line-height: 45px;
                        }
                    }
                }
            }
        }
    }
}


/* PC: with >= 1024px  */



// Mobile
@media only screen and (max-width: 46.1875em) {
    .slider {
        position   : relative;
        display    : block;
        box-sizing : border-box;
        user-select: none;
        overflow   : hidden;

        .siler-list {
            transform: translate3d(0, 0, 0);

            .slider-item {
                background-size    : cover;
                background-repeat  : no-repeat;
                background-position: 50% !important;
                display            : flex;
                align-items        : center;
                background-image   : url('https://ntq.com.vn/media/WHg55YEnRYeIlBgyPU9k/');
                height             : 100vh !important;
                min-height         : 1px;
                float              : left;
                width              : 100% !important;
                background-color   : #f1f1f1;

                .container {
                    position: relative;

                    .text {
                        overflow      : hidden;
                        // transform  : translateY(80px);

                        .text-title {
                            color            : #696969;
                            font-size        : 35px;
                            line-height      : 38px;
                            font-weight      : 800;
                            text-transform   : uppercase;
                            // margin        : 0px;
                        }

                        .sub-text {
                            color      : #696969;
                            font-size  : 20px;
                            line-height: 45px;
                        }
                    }
                }
            }
        }
    }

    .section-feature-1 {
        width        : 100%;
        display      : flex;
        // flex-wrap: wrap;
        border-bottom: 1px solid #e3dcdc;

        .col-6 {
            width: 100% !important;

            img {
                display: none !important;
            }
        }

        .title {
            font-size  : 30px;
            margin-left: 20px;
            font-weight: 700;
        }

        .text-content {
            margin-left: 20px;
            font-size  : 18px;

        }
    }

    .collapse-questions {
        align-items: center;

        .faq-container {
            display  : flex;
            width    : 100%;
            flex-wrap: wrap;

            .row-item {
                flex         : 100% 1 !important;
                padding-right: 30px;

                .faq-question {
                    display    : flex;
                    align-items: center;

                    .question-content {
                        flex: 95%;

                        .text-color {
                            color: blue;
                        }
                    }


                }
            }
        }
    }

}