.about-us {
    background              : #fff;
    position                : relative;
    font-size               : 16px;
    overflow                : hidden;
    margin                  : 0 auto;
    color                   : #60697B;
    line-height             : 1.7;
    -webkit-text-size-adjust: 100%;
    font-family             : 'Open Sans', sans-serif;
    -webkit-font-smoothing  : antialiased;

    article,
    aside,
    br,
    dialog,
    figure,
    footer,
    header,
    hr,
    main,
    menu,
    nav,
    section {
        display: block;
    }

    img,
    svg {
        vertical-align: middle;
    }

    .slider {
        position   : relative;
        display    : block;
        box-sizing : border-box;
        user-select: none;
        overflow   : hidden;

        .siler-list {
            transform: translate3d(0, 0, 0);

            .slider-item {
                background-size    : cover;
                background-repeat  : no-repeat;
                background-position: right top;
                display            : flex;
                align-items        : center;
                background-image   : url('./Hero-bg-abstract.png');
                height             : 100vh !important;
                min-height         : 1px;
                float              : left;
                width              : 100% !important;
                background-color   : #f1f1f1;

                .container {
                    position: relative;

                    .slide-content {
                        align-items       : center;
                        // justify-content: space-between;

                        .text {
                            overflow  : hidden;
                            width     : 100%;
                            text-align: center;

                            .text-title {
                                color      : #ffffff;
                                font-size  : 62px;
                                line-height: 80px;
                                font-weight: 600;

                            }

                            .sub-text {
                                color      : #ffffff;
                                font-size  : 20px;
                                line-height: 45px;
                                width      : 100%;
                                width      : 90%;
                                margin     : auto;
                            }

                            .btn-contact {
                                color           : #fff !important;
                                background-color: #1890ff;
                                border-color    : #1890ff !important;
                                font-size       : 19px;
                                padding         : 10px 25px;
                            }
                        }

                        .image {
                            width: 40%;

                            img {
                                width: 100%;
                            }
                        }
                    }

                }
            }
        }
    }

    .factsheet {
        padding-top   : 70px;
        padding-bottom: 70px;

        .title-factsheet {
            text-align   : center;
            font-size    : 40px;
            color        : #000;
            font-weight  : 500;
            margin-bottom: 50px;
        }

        .table-info {
            border            : 1px solid #efefef;
            border-radius     : 0;
            -webkit-box-shadow: 0 15px 20px 0 rgb(5 5 5/5%);
            box-shadow        : 0 15px 20px 0 rgb(5 5 5 / 5%);

            ul {
                list-style: none;
                padding   : 0;
            }

            th {
                font-size: 18px;
            }
        }
    }

    .out-mission {
        text-align: center;

        .mission-title {
            color      : #000;
            font-size  : 40px;
            font-weight: 500;
        }

        .mission-content {
            font-size: 20px;
        }
    }

    .out-vision {
        padding-top   : 70px;
        padding-bottom: 70px;
        text-align    : center;

        .mission-title {
            color      : #000;
            font-size  : 40px;
            font-weight: 500;
        }

        .mission-content {
            font-size: 20px;
            width    : 70%;
            margin   : auto;
        }
    }

    .out-values {
        text-align       : center;
        // padding-bottom: 70px;

        .mission-title {
            color      : #000;
            font-size  : 40px;
            font-weight: 500;
        }

        .mission-content {
            font-size: 20px;

        }
    }

    .benefits-of-partnering {
        // padding-top   : 70px;
        padding-bottom: 70px;

        .title-benefit {
            text-align   : center;
            color        : #000;
            font-weight  : 600;
            font-size    : 40px;
            margin-bottom: 50px;
        }

        .benefit {
            .row {
                margin-bottom: 20px;

                .content-part {
                    background-color: #f4f4f9;
                    width           : 31.33333%;
                    padding         : 30px 60px;
                    margin          : 0px 10px;

                    .icon-benefit {
                        font-size: 60px;
                    }

                    .text-benefit {
                        b {
                            font-size          : 23px;
                            color              : #000;
                            // padding-bottom  : 20px;
                        }

                        p {
                            font-size: 17px;
                        }
                    }
                }
            }

        }
    }

    .big-guys {
        padding-top   : 70px;
        padding-bottom: 70px;
        background    : #fff;

        .title-big-guy {
            font-size: 22px;
            color    : #000;
        }
    }
}

@media only screen and (max-width: 46.1875em) {
    .about-us {

        .big-guys {
            padding-top   : 0px !important;
            padding-bottom: 0px !important;

            .col-2 {
                width     : 45% !important;
                margin-top: 10px;
            }

            .left {
                margin-left: 20px;
            }

            .justify-content-center {
                justify-content: start !important;
            }

            .ml {
                margin-left: 0px !important;
            }
        }

        .container {
            position: relative;

            .slide-content {
                align-items       : center;
                // justify-content: space-between;

                .text {
                    overflow  : hidden;
                    width     : 100%;
                    text-align: center;

                    .text-title {
                        color      : #ffffff;
                        font-size  : 23px !important;
                        line-height: 40px !important;
                        font-weight: 600;

                    }

                    .sub-text {
                        color      : #ffffff;
                        font-size  : 16px !important;
                        line-height: 30px !important;
                        width      : 100%;
                        width      : 90%;
                        margin     : auto;
                    }

                    .btn-contact {
                        color           : #fff !important;
                        background-color: #1890ff;
                        border-color    : #1890ff !important;
                        font-size       : 19px;
                        padding         : 10px 25px;
                    }
                }

                .image {
                    width: 40%;

                    img {
                        width: 100%;
                    }
                }
            }

        }

        .out-mission {
            text-align: center;

            .mission-title {
                color      : #000;
                font-size  : 35px;
                font-weight: 500;
            }

            .mission-content {
                font-size: 18px;
            }
        }

        .out-vision {
            padding-top   : 50px !important;
            padding-bottom: 50px !important;
            text-align    : center;

            .mission-title {
                color      : #000;
                font-size  : 35px;
                font-weight: 500;
            }

            .mission-content {
                font-size: 18px;
                width    : 100% !important;
                margin   : auto;
            }
        }

        .out-values {
            text-align    : center;
            padding-bottom: 70px;

            .mission-title {
                color      : #000;
                font-size  : 35px;
                font-weight: 500;
            }

            .mission-content {
                font-size: 18px;

            }
        }
    }

    .benefits-of-partnering {
        .title-benefit {
            font-size: 30px !important;
        }
    }

    .benefit {
        .row {
            margin-bottom: 0px !important;
        }

        .col-4 {
            width: 90% !important;
        }

        .content-part {
            margin: 10px 0px !important;
        }
    }

}


// @media (min-width: 46.25em) and (max-width: 63.9375em) {
//     .about-us {
//         .slider-item {
//             text-align: center !important;

//             .slide-content {
//                 flex-wrap      : wrap;
//                 justify-content: center;

//                 .image {
//                     width     : 70% !important;
//                     margin-top: 50px;
//                 }

//                 .text {
//                     width      : 100% !important;
//                     margin-left: 0px !important;

//                     .sub-text {
//                         margin-left: 50px;
//                     }
//                 }
//             }
//         }
//     }

// }

@media (min-width: 64em) and (max-width: 79.5em) {
    .about-us {

        .big-guys {
            .col-2 {
                width     : 50% !important;
                margin-top: 10px;
            }

            .justify-content-center {
                justify-content: start !important;
            }

            .ml {
                margin-left: 0px !important;
            }
        }

        .slider {
            .text-title {
                // font-size: 45px !important;
            }

            .sub-text {
                font-size  : 18px !important;
                line-height: 35px !important;
            }
        }

        .benefits-of-partnering {
            .benefit {
                .content-part {
                    width : 30% !important;
                    height: 600px !important;
                }
            }
        }

        .benefit {
            .row {
                margin-bottom: 0px !important;
            }

            .col-4 {
                width: 90% !important;
            }

            .content-part {
                margin: 10px 0px !important;
            }
        }
    }
}