.contact {
    background              : #fff;
    position                : relative;
    font-size               : 16px;
    overflow                : hidden;
    margin                  : 0 auto;
    color                   : #ffffff;
    line-height             : 1.7;
    -webkit-text-size-adjust: 100%;
    font-family             : 'Open Sans', sans-serif;
    -webkit-font-smoothing  : antialiased;

    article,
    aside,
    br,
    dialog,
    figure,
    footer,
    header,
    hr,
    main,
    menu,
    nav,
    section {
        display: block;
    }

    img,
    svg {
        vertical-align   : middle;
        // width         : 100%;
    }

    .slider {
        position   : relative;
        display    : block;
        box-sizing : border-box;
        user-select: none;
        overflow   : hidden;

        .siler-list {
            transform: translate3d(0, 0, 0);

            .slider-item-contact {
                background-size    : cover;
                background-repeat  : no-repeat;
                background-position: right top;
                display            : flex;
                align-items        : center;
                background-image   : url('./Hero-bg-abstract.png');
                height             : 100vh !important;
                min-height         : 1px;
                float              : left;
                width              : 100% !important;
                background-color   : #f1f1f1;

                .container {
                    position: relative;

                    .text {
                        overflow      : hidden;
                        // transform  : translateY(80px);
                        text-align    : center;

                        .text-title {
                            color               : #ffffff;
                            font-size           : 50px;
                            line-height         : 38px;
                            font-weight         : 800;
                            // text-transform   : uppercase;
                            line-height         : 1.3;
                            // margin        : 0px;
                        }

                        .sub-text {
                            color      : #ffffff;
                            font-size  : 20px;
                            line-height: 45px;
                        }
                    }
                }
            }
        }
    }

    .contact {
        padding-top   : 70px;
        padding-bottom: 70px;

        .ant-form-item-control {
            margin-left: 15px !important;
        }

        .container {
            .row {
                .col-6 {
                    .d-flex {
                        border-bottom: 1px solid rgb(225, 225, 225);

                        .icon-contact {
                            font-size: 60px !important;
                            color    : #000;
                        }

                        .content-contact {
                            padding: 40px;

                            .title-contact {
                                font-size: 25px;
                                color    : #000;
                            }

                            .text-contact {
                                margin: 0px;
                                color : #000;
                            }

                            .contact-email {
                                color : #000;
                                margin: 0px;
                            }

                            a {
                                text-decoration: none;
                            }
                        }
                    }

                    .form-contact {
                        margin-top      : 30px;
                        background-color: #f3f3f3;
                        padding         : 45px;

                        .btn-submit {
                            width      : 100%;
                            margin-left: 180px;
                        }
                    }

                }
            }
        }
    }
}

@media (min-width: 46.25em) and (max-width: 63.9375em) {
    .contact {
        .slider {
            position   : relative;
            display    : block;
            box-sizing : border-box;
            user-select: none;
            overflow   : hidden;

            .siler-list {
                transform: translate3d(0, 0, 0);

                .slider-item-contact {
                    background-size    : cover;
                    background-repeat  : no-repeat;
                    background-position: right top;
                    display            : flex;
                    align-items        : center;
                    height             : 100vh !important;
                    min-height         : 1px;
                    float              : left;
                    width              : 100% !important;
                    background-color   : #f1f1f1;

                    .container {
                        position: relative;

                        .text {
                            overflow      : hidden;
                            // transform  : translateY(80px);

                            .text-title {
                                color            : #ffffff;
                                font-size        : 35px;
                                line-height      : 38px;
                                font-weight      : 800;
                                text-transform   : uppercase;
                                // margin        : 0px;
                            }

                            .sub-text {
                                color      : #ffffff;
                                font-size  : 20px;
                                line-height: 45px;
                            }
                        }
                    }
                }
            }
        }

        .contact {
            .container {
                .row {
                    .col-6 {
                        width: 100% !important;

                        .d-flex {
                            border-bottom: 1px solid rgb(225, 225, 225);

                            .icon-contact {
                                font-size: 50px !important;
                            }

                            .content-contact {
                                padding: 40px;

                                .title-contact {
                                    font-size: 20px !important;
                                    color    : #000;
                                }

                                .text-contact {
                                    margin: 0px;
                                    color : #000;
                                }

                                .contact-email {
                                    color : #000;
                                    margin: 0px;
                                }

                                a {
                                    text-decoration: none;
                                }
                            }
                        }

                        .form-contact {
                            margin-top      : 30px;
                            background-color: #f3f3f3;
                            padding         : 45px;

                            .btn-submit {
                                width      : 100%;
                                margin-left: 200px !important;
                            }
                        }

                    }
                }
            }
        }
    }
}


/* PC: with >= 1024px  */



// Mobile
@media only screen and (max-width: 46.1875em) {
    .contact {
        .slider {
            position   : relative;
            display    : block;
            box-sizing : border-box;
            user-select: none;
            overflow   : hidden;

            .siler-list {
                transform: translate3d(0, 0, 0);

                .slider-item-contact {
                    background-size    : cover;
                    background-repeat  : no-repeat;
                    background-position: 50%;
                    display            : flex;
                    align-items        : center;
                    height             : 100vh !important;
                    min-height         : 1px;
                    float              : left;
                    width              : 100% !important;
                    background-color   : #f1f1f1;

                    .container {
                        position: relative;

                        .text {
                            overflow      : hidden;
                            // transform  : translateY(80px);

                            .text-title {
                                color            : #ffffff;
                                font-size        : 35px !important;
                                line-height      : 38px;
                                font-weight      : 800;
                                text-transform   : uppercase;
                                // margin        : 0px;
                            }

                            .sub-text {
                                color      : #ffffff;
                                font-size  : 20px;
                                line-height: 45px;
                            }
                        }
                    }
                }
            }
        }

        .contact {
            .container {
                .row {
                    .col-6 {
                        width: 100% !important;

                        .d-flex {
                            border-bottom: 1px solid rgb(225, 225, 225);

                            .icon-contact {
                                font-size: 50px !important;
                            }

                            .content-contact {
                                padding: 40px;

                                .title-contact {
                                    font-size: 20px !important;
                                    color    : #000;
                                }

                                .text-contact {
                                    margin: 0px;
                                    color : #000;
                                }

                                .contact-email {
                                    color : #000;
                                    margin: 0px;
                                }

                                a {
                                    text-decoration: none;
                                }
                            }
                        }

                        .form-contact {
                            margin-top      : 30px;
                            background-color: #f3f3f3;
                            padding         : 45px;

                            .btn-submit {
                                width      : 100%;
                                margin-left: 0px !important;
                            }
                        }

                    }
                }
            }
        }
    }
}