.cms {
    background-color         : #F6F6F6;
    // height                : 700px;
    background-image         : url('https://ntq.com.vn/media/WHg55YEnRYeIlBgyPU9k/');
    height                   : 100vh !important;
    background-repeat        : no-repeat;
}

.cms-p {
    // padding-top: 20%;
    font-size      : 49px;
    font-weight    : 800;
    color          : #696969;
    font-style     : inherit;
    text-transform : uppercase;
    // padding-left: 5%;
}

.cms-p-unlock {
    color          : #696969;
    // padding-left: 5%;
    font-size      : 1.3rem;
    // margin-top: -1%;
}

.title-start {
    font-weight: bold;
    font-size  : 29px;
    color      : #0060ad;
    font-style : inherit;
    opacity    : 0.9;
}

.section-service {
    font-size: 18px;
    color    : #60697B;
}

p {
    font-family: inherit;
}

.p-for-title {
    font-size    : 20px;
    color        : #60697B;
    margin-bottom: 20px;
    font-weight  : bold;
}

.text-align {

    text-align: center;
}

.p-for-content {
    color        : #777F8E;
    font-size    : 15px;
    // text-align: center;

}

.image-row2 {
    width: 97%;
}

.hmHr {
    background-image     : url('./bg_hr.jpg');
    background-repeat    : no-repeat;
    background-position-x: left;
    background-position-y: center;
    background-size      : cover;
    position             : relative;
    margin-bottom        : 0;
    height               : 100%;
    width                : 100%;
    text-align           : center;

    h2 {
        font-size    : 27px;
        font-weight  : 800;
        color        : #ffffff;
        margin-bottom: 30px;
        z-index      : 2;
        position     : relative;


    }

    p {
        color        : #ffffff;
        font-size    : 19px;
        line-height  : 28px;
        margin-bottom: 20px;
        z-index      : 2;
        position     : relative;
        opacity      : 0.9;

    }

    .hmHr-but {
        background-color   : white;
        border             : none;
        color              : #0161AD;
        width              : 12.5rem;
        height             : 3.125rem;
        font-size          : 18px;
        font-weight        : 500;
        transition-duration: 0.1s;
        display            : block;
        z-index            : 2;
        position           : relative;
        // margin-left: 45%;
    }

    .hmHr-but:hover {
        background-color: #E8EFE6;
        border          : 4px solid #0161AD;
        color           : #0161AD;
    }
}

.hmHr::before {
    content         : '';
    position        : absolute;
    top             : 0;
    left            : 0;
    width           : 100%;
    height          : 100%;
    opacity         : 0.8;
    z-index         : 1;
    background      : linear-gradient(to bottom right, #0060AD 0%, #8ABA2B 100%);
    background-image: linear-gradient(to right bottom, rgb(0, 96, 173) 0%, rgb(138, 186, 43) 100%);
}

.ul-for-content {
    font-size      : 15px;
    color          : #60697B;
    list-style-type: circle;
}

.circle {
    width                : 70px;
    height               : 70px;
    -moz-border-radius   : 50px;
    -webkit-border-radius: 50px;
    border-radius        : 50px;
    text-align           : center;
    line-height          : 70px;
    color                : #3F78E0;
    font-size            : 20px;
    font-weight          : 700;
    background-color     : #E0E9FA;
}

.imageArea {
    background-image     : url('./halong-bay.jpg');
    background-repeat    : no-repeat;
    text-align           : center;
    background-size      : cover;
    background-position-x: center;
    width                : 100%;
    background-position-y: 0px;
    background-attachment: fixed;
    position             : relative;
    overflow             : hidden;

    h2 {
        font-size  : 25px;
        font-style : italic;
        color      : white;
        font-weight: 400;
        position   : relative;
        z-index    : 2;
        opacity    : 0.9;
    }
}

.imageArea::before {
    content         : '';
    position        : absolute;
    top             : 0;
    left            : 0;
    width           : 100%;
    height          : 100%;
    opacity         : 0.8;
    z-index         : 1;
    background      : linear-gradient(to bottom right, #0060AD 0%, #8ABA2B 100%);
    background-image: linear-gradient(to right bottom, rgb(0, 96, 173) 0%, rgb(138, 186, 43) 100%);
}

.section-title-1 {
    text-align: center;

    .title {
        font-size: 27px;
        color    : #60697B;
        opacity  : 0.9;
        margin   : 0;
    }

    .desc {
        color         : #0060AD;
        font-size     : 26px;
        font-weight   : bold;
        text-transform: uppercase;
    }
}

.image-row3 {
    width : 100%;
    height: 100%;
    float : right;
}

.section-title-2 {
    text-align: center;

    .title {
        font-size  : 27px;
        color      : #606060;
        font-weight: 300;
        margin     : 0;

    }

    .desc {
        font-size  : 36px;
        font-weight: 800;
        color      : #0060ad;
        display    : inline-block;
    }
}

.grad {
    background-image       : linear-gradient(to right, rgb(0, 96, 173) 0%, rgb(138, 186, 43) 100%);
    background             : linear-gradient(to right, #0060AD 0%, #8ABA2B 100%);
    background-position-x  : initial;
    background-position-y  : initial;
    background-size        : initial;
    background-attachment  : initial;
    background-origin      : initial;
    background-clip        : initial;
    background-color       : initial;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dropdownCms {
    position     : relative;
    // width: 85%;
    // border: 1px solid black;
    // float: right;
    border-bottom: 0.5px solid #D3D3D3;

    b {
        color    : #78808F;
        font-size: 18px;
    }
}

/* Nội dung Dropdown */
.noidung_dropdown,
.noidung_dropdown1,
.noidung_dropdown2,
.noidung_dropdown3,
.noidung_dropdown4,
.noidung_dropdown5,
.noidung_dropdown6,
.noidung_dropdown7 {
    display         : none;
    // width: 100%;
    // position: absolute;
    background-color: #F1F1F1;
    font-family     : inherit;
    border          : 1px solid #D3D3D3;
    border-radius   : 2px;
    float           : left;
}

.hienThi {
    display: block;
}

.but-sche {
    background-color   : #0161AD;
    border             : none;
    color              : #EEF4FA;
    width              : 300px;
    height             : 50px;
    font-size          : 20px;
    font-weight        : 500;
    transition-duration: 0.1s;
    display            : block;
    // margin-left: 49%;
    position           : relative;
    align-items        : center;
}

.but-sche:hover {
    background-color: white;
    border          : 4px solid #0161AD;
    color           : #0161AD;
}

.client {
    background-color: #F6F6F6;
    height          : 30%;
}

.section-title-3 {
    .title {
        font-size: 27px;
        color    : #60697B;
    }

    .desc {
        font-size  : 30px;
        font-weight: bold;
        color      : #0060ad;
        display    : inline-block;
        margin     : 0;
    }
}

.our-clients {
    background-color: #d8d8d8;
    padding-top     : 40px;
    padding-bottom  : 40px;

    .container {
        position: relative;

        .title {

            .text-v1 {
                font-size: 32px;
            }

            .text-v2 {
                font-size              : 30px;
                font-weight            : 600;
                color                  : #0060ad;
                display                : inline-block;
                background             : linear-gradient(to right, #0060AD 0%, #8ABA2B 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .text-v3 {
                font-size: 20px;
            }
        }
    }
}