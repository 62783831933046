.home {
  background                 : #fff;
  position                   : relative;
  font-size                  : 16px;
  overflow                   : hidden;
  margin                     : 0 auto;
  color                      : #000;
  line-height                : 1.7;
  -webkit-text-size-adjust   : 100%;
  // font-family             : Inter, Rubik, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-family                : inherit !important;
  font-style                 : inherit !important;
  -webkit-font-smoothing     : antialiased;


  article,
  aside,
  br,
  dialog,
  figure,
  footer,
  header,
  hr,
  main,
  menu,
  nav,
  section {
    display: block;
  }

  img,
  svg {
    vertical-align: middle;
  }

  .cssanimation {
    animation-duration : 3s;
    animation-fill-mode: both;
  }

  .cssanimation1 {
    animation-duration : 5s;
    animation-fill-mode: both;
  }


  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name        : fadeInRight;
    display               : block !important;
  }

  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name        : fadeInLeft;
    display               : block;
  }

  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name        : fadeInUp;
    display               : block;
  }

  @keyframes fadeInLeft {
    0% {
      opacity  : 0;
      transform: translate3d(-20%, 0, 0);
    }

    100% {
      opacity  : 1;
      transform: none
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity  : 0;
      transform: translate3d(20%, 0, 0);
    }

    100% {
      opacity  : 1;
      transform: none;
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity  : 0;
      transform: translate3d(0, 30%, 0);
    }

    100% {
      opacity  : 1;
      transform: none;
    }
  }

  .slider {
    position   : relative;
    display    : block;
    box-sizing : border-box;
    user-select: none;
    overflow   : hidden;

    .siler-list {
      transform: translate3d(0, 0, 0);

      .slider-item-home {
        background-size    : cover;
        background-repeat  : no-repeat;
        background-position: top;
        display            : flex;
        align-items        : center;
        background-image   : url('bg_home.svg');
        height             : 100vh !important;
        min-height         : 1px;
        float              : left;
        width              : 100% !important;
        background-color   : #f1f1f1;

        .container {
          position: relative;

          // margin-left: 200px;
          .slide-content {
            padding: 0;

            .text-slider {
              overflow: hidden;

              // transform: translateX(38px);
              .text-title {
                color               : #fff;
                font-size           : 50px;
                line-height         : 70px;
                font-weight         : 600;
                // text-transform   : uppercase;
                margin              : 0px;
              }

              .sub-text {
                color      : #ffffff;
                font-size  : 20px;
                line-height: 45px;
                width      : 51%;
                margin-top : 30px;
              }

              .btn-detail {
                color           : #fff !important;
                background-color: #f15f22;
                border-color    : #f15f22 !important;
                font-size       : 16px;
                padding         : 10px 25px;
              }
            }

            .form-dowload {
              position        : relative;
              z-index         : 999;
              background-color: #fff;
              border          : 1px solid #f8f8f8;
              padding         : 45px 35px;
              margin-top      : 0;
              margin-right    : 0;
              margin-bottom   : 30px;
              border-radius   : 20px;
              box-shadow      : 0 15px 20px 0 rgb(5 5 5 / 10%);

              // text-align      : center;
              .title-dowload {
                color    : #000;
                font-size: 30px;
              }

              .form-sub {
                .btn-submit {
                  width         : 100% !important;
                  // margin-left: 85px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .our-services {
    padding-top   : 70px;
    padding-bottom: 70px;

    .title-service {
      text-align   : center;
      margin-bottom: 20px;

      h1 {
        color        : #000;
        font-weight  : 600;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 20px;
        color    : #000;
      }
    }

    .mt-5 {
      margin-top: 40px !important;
    }

    .service-content {

      .row {
        justify-content: center;
      }

      .col-3 {
        width: 384px // margin-right: 5px !important;
      }

      .our-service-box {
        position                   : relative;
        // border               : 1px solid #efefef;
        // min-height              : 600px;
        border-radius              : 10px;
        -webkit-box-shadow         : 0 10px 15px 0 rgb(5 5 5/5%);
        box-shadow                 : 0 10px 15px 0 rgb(5 5 5 / 5%);
        // margin-bottom     : 16px;


        .box-service-content {
          // display                  : flex;
          // align-items        : center;
          border-radius         : 0;
          // background-color: #000000;
          opacity               : 1;
          padding               : 20px;
          min-height            : 450px;
          // min-width          : 360px;
          color                 : #fff;

          .title-box {
            font-size  : 30px;
            font-weight: 500;
          }



          .icon-box {
            font-size    : 30px;
            // margin-top: 193px; 
            margin-top   : 20px;

            // color    : #fff;
            a {
              color        : #fff;
              border       : 1px solid #fff;
              // text-align: center;
              border-radius: 50%;
              padding      : 0px 11px;

              svg {
                margin-bottom: 18px;
                font-size    : 20px;
              }
            }
          }

          .text-content {
            font-size: 16px;
            opacity  : 0;
            height   : 150px;
          }
        }

        &:hover {
          .box-service-content {
            background-image: linear-gradient(90deg, #1d2055, #3b41a5);
            opacity         : 1;
            padding         : 20px;
            min-height      : 450px;
            color           : #fff;
            border-radius   : 10px;

          }

          .icon-box {
            // font-size : 40px;
            // margin-top: 0px;
            margin-top: 20px;

            // color    : #fff;
            a {
              color: #fff;
            }
          }

          .text-content {
            font-size   : 16px;
            // display  : block;
            opacity     : 1;
          }
        }
      }

      .title-active {
        display                   : flex;
        align-items               : center;
        justify-content           : center;
        padding                   : 10px;
        border-bottom-left-radius : 5px;
        border-bottom-right-radius: 5px;
        background-color          : #1890ff;
        color                     : #fff;
        font-weight               : 800;
      }
    }
  }

  .big-guys {
    padding-top   : 70px;
    padding-bottom: 70px;
    background    : #fff;

    .ml {
      margin-left: 100px;
    }

    .title-big-guy {
      font-size: 22px;
      color    : #000;
    }
  }

  .contact-us {
    padding-top   : 140px;
    padding-bottom: 70px;

    .contact-content {
      // align-items: center;
      width : 100% !important;
      margin: auto;

    }

    .h3-xs {
      font-size    : 40px;
      font-weight  : 600;
      margin-bottom: 30px;
    }

    .p-lg {
      font-size: 18px;
    }

    .btn-contact {
      color           : #fff !important;
      background-color: #f15f22;
      border-color    : #f15f22 !important;
      font-size       : 19px;
      padding         : 10px 25px;
    }

    .img-fluid {
      width     : 90% !important;
      // display: none;
    }

    .txt-block {
      // width     : 90% !important;
      display: none;
    }
  }


  .comment {
    position         : relative;
    display          : block;
    box-sizing       : border-box;
    user-select      : none;
    overflow         : hidden;
    padding-top      : 70px;
    // padding-bottom: 0px;

    .siler-list {
      transform: translate3d(0, 0, 0);

      .slider-item-comment {
        background-size    : cover;
        background-repeat  : no-repeat;
        background-position: right top;
        display            : flex;
        align-items        : center;
        background-image   : url('./bannerbackground.jpg');
        height             : 700px;
        min-height         : 1px;
        float              : left;
        width              : 100% !important;
        background-color   : #f1f1f1;

        .container {
          position: relative;

          .text {
            overflow      : hidden;
            // transform  : translateY(80px);
            text-align    : center;

            .text-title {
              color               : #ffffff;
              font-size           : 40px;
              line-height         : 38px;
              font-weight         : 700;
              // text-transform   : uppercase;
              line-height         : 1.3;
              // margin        : 0px;
            }

          }

          .comment-content {
            .box-comment {
              background-color: #fff;
              padding         : 50px;

              // height          : 400px;
              .comment-content {
                color : #000;
                height: 220px;
              }

              .name-comment {
                font-weight  : 600;
                margin-bottom: 0;
                font-size    : 20px;
              }

              .address-name {
                margin-bottom: 1px;
              }
            }

          }

          .slick-arrow {
            margin-left: -20px !important;
          }

          .slick-dots {
            .slick-active {
              button {
                &::before {
                  opacity: 1 !important;
                  color  : white !important;
                }
              }
            }

            li {
              button {
                &::before {
                  color     : white !important;
                  font-size : 10px !important;
                  margin-top: 20px;
                }
              }
            }
          }

        }

      }
    }
  }

  .contact-us-v1 {
    // padding-top                : 0px;
    background-position-y      : 168.379px;
    font-size                  : 26px;
    // text-align              : center;
    width                      : 100%;
    height                     : auto;
    background-attachment      : fixed;
    background-position        : center 0;
    background-size            : cover;
    background-repeat          : no-repeat;
    position                   : relative;
    // overflow             : hidden;
    background-image           : url('https://res.cloudinary.com/deha-vietnam/image/upload/f_webp/images/hero/Hero-bg-2');

    .contact-content {
      display           : flex;
      justify-content   : space-between;
      // align-items    : center;

      .contact-text {
        width     : 50%;
        color     : white;
        text-align: left;
        margin-top: 50px;

        h2 {
          font-size  : 50px;
          font-weight: 500;
        }

        p {
          font-size: 23px;
        }

        .btn-contact {
          color           : #fff !important;
          background-color: #f15f22;
          border-color    : #f15f22 !important;
          font-size       : 19px;
          padding         : 10px 25px;
        }
      }


    }

    .conact-img {
      // width: 30%;

      // height: 00px;
      img {
        width        : 100%;
        // height    : auto;
        // margin-top: 90px;
        // border: 1px solid white;
      }
    }
  }
}

@media only screen and (max-width: 46.1875em) {
  .home {

    .contact-us {
      padding-top: 50px;
    }

    .slick-dots {
      .slick-active {
        button {
          &::before {
            opacity: 1 !important;
            color  : white !important;
          }
        }
      }

      li {
        button {
          &::before {
            color           : white !important;
            font-size       : 10px !important;
            margin-top      : 5px !important;
            // margin-bottom: 10px !important;
          }
        }
      }
    }

    .slider-item-home {
      background-position: 50% !important;
      text-align         : center !important;
      background-position: 0% !important;
    }

    .text-title {
      font-size  : 30px !important;
      line-height: 50px !important;
    }

    .sub-text {
      font-size  : 18px !important;
      line-height: 30px !important;
      width      : 100% !important;
    }

    .our-services {
      .service-content {
        .col-3 {
          width        : 90% !important;
          margin-bottom: 20px;
        }
      }

      .title-service {
        p {
          font-size: 16px !important;
        }
      }

      .mt-5 {
        margin-top: 0px !important;
      }
    }

    .contact-us {
      padding-bottom: 0px !important;

      .row {
        text-align: center !important;
      }

      .h3-xs {
        font-size: 30px !important;
      }

      .img-fluid {
        margin-left: 20px !important;
      }
    }

    .justify-content-center {
      justify-content: start !important;
    }

    .big-guys {
      padding-top   : 0px !important;
      padding-bottom: 0px !important;

      .col-2 {
        width     : 45% !important;
        margin-top: 10px;
      }

      .left {
        margin-left: 20px;
      }

      .justify-content-center {
        justify-content: start !important;
      }

      .ml {
        margin-left: 0px !important;
      }
    }

    .comment {
      // height: 1500px !important;

      .text {
        margin: 0px !important;
      }

      .box-comment {
        margin-left: 20px !important;
        height     : 500px !important;

        .comment-content {
          height: 280px !important;

          // height: auto !important;
        }
      }

      .slick-arrow {
        display: none !important;
      }

      .col-4 {
        width: 100% !important;
      }

      .d-flex {
        flex-wrap: nowrap !important;
      }
    }

    .contact-us-v1 {
      .contact-content {
        .contact-text {
          width        : 100% !important;
          // text-align: center;
          margin-top   : 50%;

          h2 {
            font-size: 40px;
          }
        }

        .conact-img {
          display: none !important;
        }
      }
    }
  }

}

@media (min-width: 79.5em) and (max-width: 89.5em) {
  .home {
    .our-services {
      .service-content {
        .col-3 {
          width        : 320px !important;
          margin-bottom: 20px;
        }
      }

    }

  }
}

@media (min-width: 64em) and (max-width: 79.5em) {
  .home {

    .slider {
      .text-title {
        font-size: 45px !important;
      }

      .sub-text {
        font-size: 18px !important;
      }
    }

    .contact-us {
      .h3-xs {
        margin-bottom: 0px;
        font-size    : 30px;
      }

      .p-lg {
        font-size: 16px;
      }
    }

    .our-services {
      .service-content {
        .col-3 {
          width        : 320px !important;
          margin-bottom: 20px;
        }
      }

    }
  }

  .comment {
    .box-comment {
      margin-left: 20px !important;

      .comment-content {
        height: 150px !important;
      }
    }

    .slick-arrow {
      display: none !important;
    }

    .col-4 {
      width: 100% !important;
    }

    .d-flex {
      flex-wrap: nowrap !important;
    }
  }
}

@media (min-width: 46.25em) and (max-width: 63.9375em) {
  .home {
    .slider-item-home {
      background-position: 50% !important;
    }

    .text-title {
      font-size: 35px !important;
    }

    .sub-text {
      font-size: 18px !important;
    }
  }

  .our-services {
    .service-content {
      .col-3 {
        width        : 100% !important;
        margin-bottom: 20px;
      }
    }

    .mt-5 {
      margin-top: 0px !important;
    }
  }

  .contact-us {
    .col-lg-6 {
      width     : 100% !important;
      text-align: center;
    }

    .img-fluid {
      margin-left: 30px !important;
    }
  }

  .big-guys {
    .col-2 {
      width     : 26% !important;
      margin-top: 10px !important;
    }

    .ml {
      margin-left: 0px !important;
    }
  }

  .comment {
    .box-comment {
      margin-left: 20px !important;

      .comment-content {
        height: 150px !important;
      }
    }

    .slick-arrow {
      display: none !important;
    }

    .col-4 {
      width: 100% !important;
    }

    .d-flex {
      flex-wrap: nowrap !important;
    }
  }

  .contact-us-v1 {
    // height: 300px !important;

    .contact-content {
      .contact-text {
        width        : 100% !important;
        // text-align: center;
        margin-top   : 50%;

        h2 {
          font-size: 35px;
        }
      }

      .conact-img {
        // display: none !important;
      }
    }
  }
}